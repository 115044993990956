import './style.css'
import Particles from "react-tsparticles";

const GraphBackground = () =>{

    return <Particles
        id={'graphBackground'}
        url={'assets/config/particles.json'}
    />
}
export default GraphBackground